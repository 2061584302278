body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin-bottom: 0px;
    font-size: 14px;
    color: #666666;
    line-height: 30px;
}

a {
    text-decoration: none!important;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

a.filled-button {
    background-color: #B30000;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 30px;
    display: inline-block;
    transition: all 0.3s;
}

a.filled-button:hover {
    background-color: #fff;
    color: #B30000;
}

a.border-button {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 28px;
    border-radius: 30px;
    display: inline-block;
    transition: all 0.3s;
}

a.border-button:hover {
    background-color: #fff;
    color: #B30000;
}

.section-heading {
    text-align: center;
    margin-bottom: 80px;
}

.section-heading h2 {
    font-size: 36px;
    font-weight: 600;
    color: #1e1e1e;
}

.section-heading em {
    font-style: normal;
    color: #B30000;
}

.section-heading span {
    display: block;
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 15px;
    color: #666;
    letter-spacing: 1px;
}

#preloader {
    overflow: hidden;
    background: #B30000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999999;
    color: #fff;
}

#preloader .jumper {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    margin: auto;
    width: 50px;
    height: 50px;
}

#preloader .jumper>div {
    background-color: #fff;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    opacity: 0;
    width: 50px;
    height: 50px;
    -webkit-animation: jumper 1s 0s linear infinite;
    animation: jumper 1s 0s linear infinite;
}

#preloader .jumper>div:nth-child(2) {
    -webkit-animation-delay: 0.33333s;
    animation-delay: 0.33333s;
}

#preloader .jumper>div:nth-child(3) {
    -webkit-animation-delay: 0.66666s;
    animation-delay: 0.66666s;
}

@-webkit-keyframes jumper {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    5% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes jumper {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    5% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* Sub Header Style */

.sub-header {
    background-color: #343434;
    height: 46px;
    line-height: 46px;
}

.sub-header ul li {
    display: inline-block;
}

.sub-header ul.left-info li {
    border-left: 1px solid rgba(250, 250, 250, 0.3);
    padding: 0px 20px;
}

.sub-header ul.left-info li:last-child {
    border-right: 1px solid rgba(250, 250, 250, 0.3);
}

.sub-header ul.left-info li i {
    margin-right: 10px;
    font-size: 18px;
}

.sub-header ul.left-info li a {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.sub-header ul.right-icons {
    float: right;
}

.sub-header ul.right-icons li {
    margin-right: -4px;
    width: 46px;
    display: inline-block;
    text-align: center;
    border-right: 1px solid rgba(250, 250, 250, 0.3);
}

.sub-header ul.right-icons li:first-child {
    border-left: 1px solid rgba(250, 250, 250, 0.3);
}

.sub-header ul.right-icons li a {
    color: #fff;
    transition: all 0.3s;
}

.sub-header ul.right-icons li a:hover {
    opacity: 0.75;
}


/* Header Style */

header {
    position: absolute;
    z-index: 99999;
    width: 100%;
    background-color: transparent!important;
    height: 80px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

header .navbar {
    padding: 20px 0px;
}

.background-header .navbar {
    padding: 10px 0px;
}

.background-header {
    top: 0;
    position: fixed;
    background-color: #fff!important;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.background-header .navbar-brand h2 {
    color: #B30000!important;
}

.background-header .navbar-nav a.nav-link {
    color: #1e1e1e!important;
}

.background-header .navbar-nav .nav-link:hover,
.background-header .navbar-nav .active>.nav-link,
.background-header .navbar-nav .nav-link.current,
.background-header .navbar-nav .nav-link.show,
.background-header .navbar-nav .show>.nav-link {
    color: #B30000!important;
}

.navbar .navbar-brand {
    float: left;
    margin-top: 12px;
    outline: none;
}

.navbar .navbar-brand h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

.navbar .navbar-brand h2 em {
    font-style: normal;
    font-size: 16px;
}

#navbarResponsive {
    z-index: 999;
}

.navbar-collapse {
    text-align: center;
}

.navbar .navbar-nav .nav-item {
    margin: 0px 15px;
}

.navbar .navbar-nav a.nav-link {
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #b2b1b1;
    transition: all 0.5s;
    margin-top: 5px;
}

.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .active>.nav-link,
.navbar .navbar-nav .nav-link.current,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .show>.nav-link {
    color: #fff;
    border-bottom: 3px solid #82CFFD;
}

.navbar .navbar-toggler-icon {
    background-image: none;
}

.navbar .navbar-toggler {
    border-color: #fff;
    background-color: #fff;
    height: 36px;
    outline: none;
    border-radius: 0px;
    position: absolute;
    right: 30px;
    top: 20px;
}

.navbar .navbar-toggler-icon:after {
    content: '\f0c9';
    color: #B30000;
    font-size: 18px;
    line-height: 26px;
    font-family: 'FontAwesome';
}


/* Banner Style */

.img-fill {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    text-align: center
}

.img-fill img {
    min-height: 100%;
    min-width: 100%;
    position: relative;
    display: inline-block;
    max-width: none;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Grid1k {
    padding: 0 15px;
    max-width: 1200px;
    margin: auto;
}

.blocks-box,
.slick-slider {
    margin: 0;
    padding: 0!important;
}

.slick-slide {
    float: left;
    padding: 0;
}

.Modern-Slider .item .img-fill {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 95vh;
}

.Modern-Slider .item-1 .img-fill {
    background-image: url(../public/images/slide_01.png);
}

.Modern-Slider .item-2 .img-fill {
    background-image: url(../public/images/slide_02.png);
}

.Modern-Slider .item-3 .img-fill {
    background-image: url(../public/images/slide_01.png);
}

ul.slick-dots {
    display: none!important;
}

.Modern-Slider .text-content {
    text-align: left;
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Modern-Slider .item h6 {
    margin-bottom: 15px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    color: #E5E5E5;
    animation: fadeOutRight 1s both;
}

.Modern-Slider .item h4 {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 2.5px;
    color: #FFF;
    overflow: hidden;
    animation: fadeOutLeft 1s both;
}

.Modern-Slider .item p {
    max-width: 570px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 40px;
}

.Modern-Slider .item a {
    margin: 0 5px;
}

.Modern-Slider .item.slick-active h6 {
    animation: fadeInDown 1s both 1s;
}

.Modern-Slider .item.slick-active h4 {
    animation: fadeInLeft 1s both 1.5s;
}

.Modern-Slider .item.slick-active {
    animation: Slick-FastSwipeIn 1s both;
}

.Modern-Slider .buttons {
    position: relative;
}

.Modern-Slider {
    background-color: #B30000;
}


/* ==== Slick Slider Css Ruls === */

.slick-slider {
    position: relative;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: hand
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left/* If RTL Make This Right */
    ;
    height: 100%;
    min-height: 1px
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}


/* Request Form */

.request-form {
    background-color: #B30000;
    padding: 40px 0px;
    color: #fff;
}

.request-form h4 {
    font-size: 22px;
    font-weight: 600;
}

.request-form span {
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    margin-top: 10px;
}

.request-form a.border-button {
    margin-top: 12px;
    float: right;
}


/* Services */

.services {
    margin-top: 70px;
}

.service-item img {
    width: 100%;
    overflow: hidden;
}

.service-item .down-content {
    background-color: #f7f7f7;
    padding: 30px;
}

.service-item .down-content h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 15px;
}

.service-item .down-content p {
    margin-bottom: 20px;
}


/* Fun Facts */

.fun-facts {
    margin-top: 140px;
    background-image: url(../public/images/fun-facts-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 140px 0px;
    color: #fff;
}

.fun-facts span {
    text-transform: uppercase;
    font-size: 15px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 10px;
    display: block;
}

.fun-facts h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 35px;
}

.fun-facts em {
    font-style: normal;
    color: #B30000;
}

.fun-facts p {
    color: #fff;
    margin-bottom: 40px;
}

.fun-facts .left-content {
    margin-right: 45px;
}

.count-area-content {
    text-align: center;
    background-color: rgba(250, 250, 250, 0.1);
    padding: 25px 30px 35px 30px;
    margin: 15px 0px;
}

.count-digit {
    margin: 5px 0px;
    color: #B30000;
    font-weight: 700;
    font-size: 36px;
}

.count-title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.5px;
}


/* More Info */

.more-info {
    margin-top: 40px;
}

.more-info .left-image img {
    width: 100%;
    overflow: hidden;
    padding-top: 100px;
}

.more-info .more-info-content {
    background-color: #f7f7f7;
}

.about-info .more-info-content {
    background-color: transparent;
}

.about-info .right-content {
    padding: 0px!important;
    margin-right: 30px;
}

.more-info .right-content {
    padding: 45px 45px 45px 30px;
}

.more-info .right-content span {
    text-transform: uppercase;
    font-size: 15px;
    color: #666;
    letter-spacing: 1px;
    margin-bottom: 10px;
    display: block;
}

.more-info .right-content h2 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 35px;
}

.more-info .right-content em {
    font-style: normal;
    color: #B30000;
}

.more-info .right-content p {
    margin-bottom: 30px;
}


/* Testimonials Style */

.about-testimonials {
    margin-top: 0px!important;
}

.testimonials {
    margin-top: 0.5px;
    background-color: #f7f7f7;
    padding: 40px 0px;
}

.testimonial-item .inner-content {
    text-align: center;
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
}

.testimonial-item p {
    font-size: 14px;
    font-weight: 400;
}

.testimonial-item h4 {
    font-size: 19px;
    font-weight: 700;
    color: #1e1e1e;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
}

.testimonial-item span {
    display: inline-block;
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #B30000;
}

.testimonial-item img {
    max-width: 60px;
    border-radius: 50%;
    margin: 25px auto 0px auto;
}


/* Call Back Style */

.callback-services {
    border-top: 1px solid #eee;
    padding-top: 15px;
}

.contact-us {
    background-color: #f7f7f7;
    padding: 140px 0px;
}

.contact-us .contact-form {
    background-color: transparent!important;
    padding: 0!important;
}

.callback-form {
    margin-top: 140px;
}

.callback-form .contact-form {
    background-color: #B30000;
    padding: 60px;
    border-radius: 5px;
    text-align: center;
}

.contact-form h5 {
    /* background-color: #B30000; */
    padding: 60px;
    color: #FFF;
    border-radius: 5px;
    letter-spacing: 1px;
    font-size: 25px;
    text-align: center;
}

.callback-form .contact-form input {
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    padding: 0px 15px;
    color: #6a6a6a;
    font-size: 13px;
    text-transform: none;
    box-shadow: none;
    border: none;
    margin-bottom: 35px;
}

.callback-form .contact-form input:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.callback-form .contact-form textarea {
    border-radius: 20px;
    height: 120px;
    max-height: 200px;
    min-height: 120px;
    display: inline-block;
    padding: 15px;
    color: #6a6a6a;
    font-size: 13px;
    text-transform: none;
    box-shadow: none;
    border: none;
    margin-bottom: 35px;
}

.callback-form .contact-form textarea:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.callback-form .contact-form ::-webkit-input-placeholder {
    /* Edge */
    color: #aaa;
}

.callback-form .contact-form :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
}

.callback-form .contact-form ::placeholder {
    color: #aaa;
}

.callback-form .contact-form button.border-button {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 28px;
    border-radius: 30px;
    display: inline-block;
    transition: all 0.3s;
    outline: none;
    box-shadow: none;
    text-shadow: none;
    cursor: pointer;
}

.callback-form .contact-form button.border-button:hover {
    background-color: #fff;
    color: #B30000;
}

.contact-us .contact-form button.filled-button {
    background-color: #B30000;
    color: #fff;
    border: none;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 30px;
    display: inline-block;
    transition: all 0.3s;
    outline: none;
    box-shadow: none;
    text-shadow: none;
    cursor: pointer;
}

.contact-us .contact-form button.filled-button:hover {
    background-color: #fff;
    color: #B30000;
}


/* Partners Style */

.contact-partners {
    margin-top: -8px!important;
}

.partners {
    margin-top: 140px;
    background-color: #f7f7f7;
    padding: 60px 0px;
}

.partners .owl-item {
    text-align: center;
    cursor: pointer;
}

.partners .partner-item img {
    max-width: 156px;
    margin: auto;
}


/* Footer Style */

footer {
    background-color: #232323;
    padding: 30px 0px;
    color: #fff;
}

footer h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.25px;
    margin-bottom: 35px;
}

footer p {
    color: #fff;
}

footer ul.social-icons {
    margin-top: 25px;
}

footer ul.social-icons li {
    display: inline-block;
    margin-right: 5px;
}

footer ul.social-icons li:last-child {
    margin-right: 0px;
}

footer ul.social-icons li a {
    width: 34px;
    height: 34px;
    display: inline-block;
    line-height: 34px;
    text-align: center;
    background-color: #fff;
    color: #232323;
    border-radius: 50%;
    transition: all 0.3s;
}

footer ul.social-icons li a:hover {
    background-color: #FFC72C;
}

footer ul.menu-list li {
    margin-bottom: 13px;
}

footer ul.menu-list li:last-child {
    margin-bottom: 0px;
}

footer ul.menu-list li a {
    font-size: 14px;
    color: #fff;
    transition: all 0.3s;
}

footer ul.menu-list li a:hover {
    color: #FFC72C;
}

footer .contact-form input {
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    padding: 0px 15px;
    color: #aaa!important;
    background-color: #343434;
    font-size: 13px;
    text-transform: none;
    box-shadow: none;
    border: none;
    margin-bottom: 15px;
}

footer .contact-form input:focus {
    outline: none;
    box-shadow: none;
    border: none;
    background-color: #343434;
}

footer .contact-form textarea {
    border-radius: 20px;
    height: 120px;
    max-height: 200px;
    min-height: 120px;
    display: inline-block;
    padding: 15px;
    color: #aaa!important;
    background-color: #343434;
    font-size: 13px;
    text-transform: none;
    box-shadow: none;
    border: none;
    margin-bottom: 15px;
}

footer .contact-form textarea:focus {
    outline: none;
    box-shadow: none;
    border: none;
    background-color: #343434;
}

footer .contact-form ::-webkit-input-placeholder {
    /* Edge */
    color: #aaa;
}

footer .contact-form :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
}

footer .contact-form ::placeholder {
    color: #aaa;
}

footer .contact-form button.filled-button {
    background-color: transparent;
    color: #fff;
    background-color: #B30000;
    border: none;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 30px;
    display: inline-block;
    transition: all 0.3s;
    outline: none;
    box-shadow: none;
    text-shadow: none;
    cursor: pointer;
}

footer .contact-form button.filled-button:hover {
    background-color: #fff;
    color: #B30000;
}


/* Sub-footer Style */

.sub-footer {
    background-color: #343434;
    text-align: center;
    padding: 15px 0px;
}

.sub-footer p {
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.sub-footer a {
    color: #fff;
}

.page-heading {
    text-align: center;
    background-image: url(../public/images/page-heading-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 230px 0px 150px 0px;
    color: #fff;
}

.page-heading h1 {
    text-transform: capitalize;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 18px;
}

.page-heading span {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    display: block;
}


/* team */

.team {
    background-color: #f7f7f7;
    margin-top: 140px;
    margin-bottom: -140px;
    padding: 120px 0px;
}

.team-item img {
    width: 100%;
    overflow: hidden;
}

.team-item .down-content {
    background-color: #fff;
    padding: 30px;
}

.team-item .down-content h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 10px;
}

.team-item .down-content span {
    color: #B30000;
    font-weight: 600;
    font-size: 14px;
    display: block;
    margin-bottom: 15px;
}

.team-item .down-content p {
    margin-bottom: 20px;
}


/* Single Service */

.single-services {
    margin-top: 140px;
}

#tabs ul {
    margin: 0;
    padding: 0;
}

#tabs ul li {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
}

#tabs ul li:last-child {
    margin-bottom: 0px;
}

#tabs ul li a {
    text-transform: capitalize;
    width: 100%;
    padding: 30px 30px;
    display: inline-block;
    background-color: #B30000;
    font-size: 20px;
    color: #ececec;
    letter-spacing: 0.5px;
    font-weight: 700;
    transition: all 0.3s;
}

#tabs ul li a i {
    float: right;
    margin-top: 5px;
}

#tabs ul .ui-tabs-active span {
    background: #faf5b2;
    border: #faf5b2;
    line-height: 90px;
    border-bottom: none;
}

#tabs ul .ui-tabs-active a {
    color: #fff;
}

#tabs ul .ui-tabs-active span {
    color: #1e1e1e;
}

.tabs-content {
    margin-left: 30px;
    text-align: left;
    display: inline-block;
    transition: all 0.3s;
}

.tabs-content img {
    max-width: 100%;
    overflow: hidden;
}

.tabs-content h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 15px;
    margin-top: 30px;
}

.tabs-content p {
    font-size: 14px;
    color: #7a7a7a;
    margin-bottom: 0px;
}


/* Contact Information */

.contact-information {
    margin-top: 140px;
}

.contact-information .contact-item {
    padding: 60px 30px;
    background-color: #f7f7f7;
    text-align: center;
}

.contact-information .contact-item i {
    color: #B30000;
    font-size: 48px;
    margin-bottom: 40px;
}

.contact-information .contact-item h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 15px;
}

.contact-information .contact-item p {
    margin-bottom: 20px;
}

.contact-information .contact-item a {
    font-weight: 600;
    color: #B30000;
    font-size: 15px;
}


/* Responsive Style */

@media (max-width: 768px) {
    .sub-header {
        display: none;
    }
    .Modern-Slider .item h6 {
        margin-bottom: 15px;
        font-size: 18px;
    }
    .Modern-Slider .item h4 {
        margin-bottom: 25px;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 1px;
    }
    .Modern-Slider .item p {
        max-width: 570px;
        line-height: 25px;
        margin-bottom: 30px;
    }
    .Modern-Slider .NextArrow {
        right: 5px;
    }
    .Modern-Slider .PrevArrow {
        left: 5px;
    }
    .request-form {
        text-align: center;
    }
    .request-form a.border-button {
        float: none;
        margin-top: 30px;
    }
    .services .service-item {
        margin-bottom: 30px;
    }
    .fun-facts .left-content {
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .more-info .right-content {
        padding: 30px;
    }
    footer {
        padding: 80px 0px 20px 0px;
    }
    footer .footer-item {
        border-bottom: 1px solid #343434;
        margin-bottom: 30px;
        padding-bottom: 30px;
    }
    footer .last-item {
        border-bottom: none;
    }
    .about-info .right-content {
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .team .team-item {
        margin-bottom: 30px;
    }
    .tabs-content {
        margin-left: 0px;
        margin-top: 30px;
    }
    .contact-item {
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .navbar .navbar-brand {
        position: absolute;
        left: 30px;
        top: 10px;
    }
    .navbar .navbar-brand {
        width: auto;
    }
    .navbar:after {
        display: none;
    }
    #navbarResponsive {
        z-index: 99999;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: #fff;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    }
    .navbar .navbar-nav .nav-item {
        border-bottom: 1px solid #eee;
    }
    .navbar .navbar-nav .nav-item:last-child {
        border-bottom: none;
    }
    .navbar .navbar-nav a.nav-link {
        padding: 15px 0px;
        color: #1e1e1e!important;
    }
    .navbar .navbar-nav .nav-link:hover,
    .navbar .navbar-nav .active>.nav-link,
    .navbar .navbar-nav .nav-link.current,
    .navbar .navbar-nav .nav-link.show,
    .navbar .navbar-nav .show>.nav-link {
        color: #B30000!important;
        border-bottom: none!important;
    }
}